




































import { ref as _ref } from "@vue/composition-api";
import { defaultListQuery } from "@/select2/api/base";
import Api, { Params, AdvertiserData, Advertiser } from "@/select2/advertiser";
import Pagination from "@/components/Pagination/index.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;

  let count = _ref(0);

  let listQuery: Params = _ref({ ...defaultListQuery
  });

  let advertisers: any[] = _ref([]);

  let loading = _ref(true);

  async function load() {
    const data = await Api.list(listQuery.value);
    advertisers.value = data.results.map(a => ({ ...a,
      teams: a.teams.map(t => t.name).join(", "),
      pricing_model: a.pricing_model?.name,
      agency: a.agency?.name
    }));
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  onMounted(() => {
    load();
  });
  return {
    isAdmin,
    count,
    listQuery,
    advertisers,
    loading,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
