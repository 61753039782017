import { ListParams } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "./base"

export interface Params extends ListParams {
	name?: string
}

export interface Advertiser {
	id?: number
	name: string
	agency: Lookup | number | null
	pricing_model: Lookup | number | null
	display_brand_safe: null
	video_brand_safe: null
	teams: Lookup[] | number[]
}

export interface AdvertiserForm extends Omit<Advertiser, "id"> {
	pricing_model: number
	teams: number[]
}
export interface AdvertiserData extends Advertiser {
	id: number
	agency: Lookup
	pricing_model: Lookup
}

export const defaultAdvertiserData: Advertiser = {
	name: "",
	agency: null,
	pricing_model: null,
	display_brand_safe: null,
	video_brand_safe: null,
	teams: [],
}

export default Generate<AdvertiserForm, AdvertiserData>("/advertisers")
